<template>
  <div class="question">
    <div v-if="currentQuestionIndexRef === null">
      <p>Are you ready to start?</p>
      <button @click="currentQuestionIndexRef = 0">YES!</button>
    </div>
    <div v-else-if="completedRef">
      <p>Awesome! You're done!</p>
    </div>
    <template v-else>
      <span v-if="errorRef" class="error">{{ errorRef }}</span>
      <PickThePronoun v-if="currentQuestionRef.type === 'pronoun'" @answer="onAnswer(currentQuestionIndexRef, $event)" />
      <PickTheVerb v-if="currentQuestionRef.type === 'verb'" @answer="onAnswer(currentQuestionIndexRef, $event)" />
    </template>
  </div>
</template>

<script setup>
import { computed, ref, } from "vue";
import PickThePronoun from "./questions/PickThePronoun.vue";
import PickTheVerb from "./questions/PickTheVerb.vue";
const questions = [
  {
    type: "pronoun",
    correct: 0
  },
  {
    type: "verb",
    correct: 1
  }
];
const currentQuestionIndexRef = ref(null);
const currentQuestionRef = computed(() => questions[currentQuestionIndexRef.value]);
const errorRef = ref("");
const completedRef = ref(false);

const onAnswer = (questionIndex, answer) => {
  const question = questions[questionIndex];
  if(!question) {
    errorRef.value = "This question does not exist!";
    return;
  }
  if(!answer) {
    errorRef.value = "Please answer the question!";
    return;
  }
  if(question.correct !== parseInt(answer)) {
    errorRef.value = "This answer is not correct!";
    return;
  }
  if(questions.length === questionIndex + 1) {
    completedRef.value = true;
    return;
  }
  errorRef.value = "";
  currentQuestionIndexRef.value = ++questionIndex;
};
</script>

<style scoped>
.question {
  border: 1px solid #ccc;
  padding: 20px;
}
.error {
  display: block;
  padding: 20px;
  border: 1px solid red;
  background-color: red;
  color: #fff;
}
</style>