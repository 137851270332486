<template>
    <div>
        You
        <select v-model="answerRef">
            <option value="">Please choose</option>
            <option value="0">am</option>
            <option value="1">are</option>
        </select>
        Chinese
    </div>
    <button @click="$emit('answer', answerRef)">Next</button>
</template>

<script setup>
import { ref, } from "vue";
defineEmits(["answer"]);
const answerRef = ref("");
</script>